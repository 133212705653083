import { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'gatsby'

const NotFoundCategories = () => {
  const [suggestions, setSugesttions] = useState<[{ term: string }] | null>(
    null
  )

  async function getSugestions() {
    try {
      const { data } = await axios.get(
        'https://decathlonstore.myvtex.com/_v/api/decathlon-search/top_searches?engine=vtex'
      )

      setSugesttions(data.searches)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getSugestions()
  }, [])

  return (
    <div className="mt-8 sm:mt-10">
      <ul className="flex flex-wrap gap-4">
        {suggestions?.map((suggestion) => (
          <li
            className="bg-restructure-background-secondary border text-sm font-inter font-normal text-restructure-secondary border-restructure-border-secondary rounded-round px-sm py-xs cursor-pointer"
            key={suggestion.term}
          >
            <Link to={`/pesquisa?q=${suggestion.term}&sort=score_desc&page=0`}>
              {`${suggestion.term.charAt(0).toUpperCase()}${suggestion.term
                .slice(1, suggestion.term.length)
                .toLowerCase()}`}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default NotFoundCategories
